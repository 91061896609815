import { LinkObjectType } from './../../lib/helpers/types';
import rpcShared from '@rockpapercoin/rpc-shared';
import { getDetailLink, toSimpleLink } from '../../lib/helpers/index';

export type EventItem = {
	icon?: string;
	message?: React.ReactNode;
	links?: {
		type: string;
		text?: string;
		link?: ReturnType<typeof getDetailLink>;
	}[];
};

export enum LinkCustomerLinkType {
	ClientUser = 'clientUser',
	GuestUser = 'guestUser',
	OrgUser = 'orgUser',
	Contact = 'contact',
}

export interface LinkCustomerEvent {
	clientUser?: {
		firstNameOne: string;
		lastNameOne: string;
		user: { id: string };
	};
	guestUser?: { user: { id: string; email: string } };
	orgUser?: { firstName: string; lastName: string; user: { id: string } };
	contact?: Parameters<typeof rpcShared.contacts.getCustomerName>[0] | null;
}

export const linkCustomer = (
	event: LinkCustomerEvent,
	linkType?: LinkCustomerLinkType
) => {
	const { clientUser, guestUser, orgUser, contact } = event;
	// figure out what the name is, preferring contact data
	if ( linkType && !event[ linkType ] ) {
		throw new Error( `No ${ linkType } provided` );
	}
	const type =
		linkType ||
		( contact ? LinkCustomerLinkType.Contact : undefined ) ||
		( clientUser ? LinkCustomerLinkType.ClientUser : undefined ) ||
		( guestUser ? LinkCustomerLinkType.GuestUser : undefined ) ||
		( orgUser ? LinkCustomerLinkType.OrgUser : undefined );
	let text: string | undefined;
	let object: Record<string, any> | undefined;
	switch ( type ) {
		case LinkCustomerLinkType.ClientUser:
			text = `${ clientUser?.firstNameOne } ${ clientUser?.lastNameOne }`;
			object = clientUser;
			break;
		case LinkCustomerLinkType.GuestUser:
			text = guestUser?.user?.email;
			object = guestUser;
			break;
		case LinkCustomerLinkType.OrgUser:
			text = `${ orgUser?.firstName } ${ orgUser?.lastName }`;
			object = orgUser;
			break;
		case LinkCustomerLinkType.Contact:
			if ( !contact ) {
				throw new Error( 'No contact provided.' );
			} else if ( !contact.customer ) {
				throw new Error( 'No customer provided.' );
			}
			text = rpcShared.contacts.getCustomerName( contact ).fullName;
			object = contact;
			break;
	}
	if ( !object ) {
		return text;
	} else {
		return toSimpleLink( {
			link: getDetailLink( {
				object,
				// be backwards-compatible
				type:
					type === LinkCustomerLinkType.Contact
						? LinkObjectType.Contact
						: undefined,
			} ),
			text,
		} );
	}
};

export type PaymentInstallmentForNames = {
	name: string;
};

export const getPaymentInstallmentNames = (
	paymentInstallments: PaymentInstallmentForNames[]
) => {
	const totalInstallments = paymentInstallments.length;
	if ( totalInstallments === 1 ) {
		return paymentInstallments[ 0 ].name;
	}
	if ( totalInstallments === 2 ) {
		return `${ paymentInstallments[ 0 ].name } and ${ paymentInstallments[ 1 ].name }`;
	}
	if ( totalInstallments > 2 ) {
		let copy = '';
		paymentInstallments.forEach( ( installment, i ) => {
			if ( i === totalInstallments - 1 ) {
				copy += `and ${ installment.name }`;
			} else {
				copy += `${ installment.name }, `;
			}
		} );
		return copy;
	}
};

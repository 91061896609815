import React from 'react';
import { ProposalsIcon } from '../../../mui/Icons/index';
import API from '../../../lib/API/index';
import { showError, showSuccess } from '../../Toast';
import { linkCustomer, LinkCustomerLinkType } from '../helpers';
import rpcShared from '@rockpapercoin/rpc-shared';
import { toSimpleLink } from '../../../lib/helpers';
import { gql } from '@apollo/client';
import { GetProposalPastDueEventPropsEventFragment } from './__generated__/getProposalPastDueEventProps';
import { Typography } from '@mui/material';
import { UserType } from '../../../types/generated';
import { FlattenedUser } from '../../../types/user';

export const getProposalPastDueEventPropsEventFragment = gql`
	fragment getProposalPastDueEventPropsEvent on Event {
		category
		resolvedBy {
			id
		}
		proposal {
			id
			title
			sentByUser {
				id
			}
			contact {
				firstName
				lastName
				customer {
					id
					email
					clientUser {
						assignedPlanner {
							id
							organization {
								id
							}
						}
					}
				}
				assignedMember {
					id
				}
				vendor {
					id
					name
					users {
						id
						role
					}
				}
			}
		}
	}
`;

const resendProposal = async function( id: string, refresh: () => void ) {
	// there is no bundling with resends
	const response = await API.sendProposal( id, null );

	if ( !( 'errors' in response ) ) {
		showSuccess();
		if ( refresh ) {
			return refresh();
		}
	} else {
		response.errors.map( ( error ) => showError( error ) );
	}
};

interface GetProposalPastDueEventProps {
	defaultEventProps: {
		event: GetProposalPastDueEventPropsEventFragment;
		message: JSX.Element;
		links: {
			type: string;
			text: string;
			link: any;
			onClick?: ( () => any ) | null;
		}[];
		icon: JSX.Element | string;
		className: string;
	};
	user: FlattenedUser;
	refresh: () => void;
}

export default function getProposalPastDueEventProps( {
	defaultEventProps,
	user,
	refresh,
}: GetProposalPastDueEventProps ) {
	const orgUser =
		user?.isLoggedIn && user.userType === UserType.OrgUser ? user : undefined;
	const updatedEventProps = { ...defaultEventProps };
	const proposalLinkProps = {
		text: updatedEventProps.event.proposal?.title,
		link: {
			as: `/proposal/${ updatedEventProps.event.proposal?.id }`,
			href: '/proposal/[id]',
		},
	};
	const proposalLink = toSimpleLink( proposalLinkProps );
	const customerLink = updatedEventProps.event.proposal
		? linkCustomer(
			{ contact: updatedEventProps.event.proposal.contact },
			LinkCustomerLinkType.Contact
		  )
		: undefined;
	const customerNameOrLink =
		updatedEventProps.event.proposal?.contact && orgUser
			? updatedEventProps.event.proposal?.contact?.vendor.id ===
					orgUser?.organization.id ||
			  rpcShared.contacts.isAssignedPlannerOrAdminOfCustomersPlannerOrg(
			  	updatedEventProps.event.proposal.contact,
			  	orgUser
			  )
				? customerLink
				: rpcShared.contacts.getCustomerName(
					updatedEventProps.event.proposal.contact
				  ).fullName
			: undefined;
	const vendorLink = toSimpleLink( {
		text: updatedEventProps.event.proposal?.contact?.vendor.name,
		link: {
			as: `/org/${ updatedEventProps.event.proposal?.contact?.vendor.id }`,
			href: '/org/[id]',
		},
	} );
	const vendorNameOrLink =
		updatedEventProps.event.proposal?.contact?.vendor.id ===
		orgUser?.organization.id
			? vendorLink
			: updatedEventProps.event.proposal?.contact?.vendor.name;
	updatedEventProps.icon = <ProposalsIcon />;
	updatedEventProps.links = [
		{
			type: 'secondary',
			text: 'View',
			link: proposalLinkProps.link,
		},
	];

	const getResendOrViewLink = () => {
		return {
			type: updatedEventProps.event.resolvedBy ? 'secondary' : 'primary',
			text: updatedEventProps.event.resolvedBy ? 'View' : 'Resend',
			link: updatedEventProps.event.resolvedBy ? proposalLink : null,
			onClick: updatedEventProps.event.resolvedBy
				? null
				: () =>
					updatedEventProps.event.proposal?.id
						? resendProposal( updatedEventProps.event.proposal.id, refresh )
						: undefined,
		};
	};

	if ( !user?.isLoggedIn || !updatedEventProps.event.proposal?.contact ) {
		// when singing out the user data we need will not be supplied, so do nothing
		return null;
	} else if (
		rpcShared.contacts.isCustomer(
			updatedEventProps.event.proposal.contact,
			user.user.id
		)
	) {
		updatedEventProps.message = (
			<Typography>
				{ proposalLink } from { vendorNameOrLink } is past due.
			</Typography>
		);
	} else if (
		user.userType === UserType.OrgUser &&
		( rpcShared.contacts.isAdminOrManagerOfVendor(
			updatedEventProps.event.proposal.contact,
			user
		) ||
			rpcShared.contacts.isAssignedMember(
				updatedEventProps.event.proposal.contact,
				user
			) )
	) {
		updatedEventProps.message = (
			<Typography>{ proposalLink } is past due. Resend?</Typography>
		);
		updatedEventProps.links = [ getResendOrViewLink() ];
	} else {
		updatedEventProps.message = (
			<Typography className='p--secondary'>
				A proposal from { vendorNameOrLink } that was sent to { customerNameOrLink }{ ' ' }
				is past due: { proposalLink }.
			</Typography>
		);
	}

	return updatedEventProps;
}

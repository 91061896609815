import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Elements
import { Divider, List, Typography } from '@mui/material';

// Blocks
import EventRow from './EventRow';
import ErrorBoundary from '../ErrorBoundary';
import { showSuccess, showError } from '../Toast';

// Lib
import {
	getDetailLink,
	getUsername,
	isOrgUser,
	toSimpleLink,
	linkObject,
	isAssignedPlannerOrAdmin,
} from '../../lib/helpers';
import API from '../../lib/API';
import errorReporting from '../../lib/ErrorReporting';
import documentEvents from './documents';
import rpcShared from '@rockpapercoin/rpc-shared';
import getProposalSentEventProps from './proposal/getProposalSentEventProps';
import getProposalPastDueEventProps from './proposal/getProposalPastDueEventProps';
import getProposalDueSoonEventProps from './proposal/getProposalDueSoonEventProps';
import getProposalAcceptedEventProps from './proposal/getProposalAcceptedEventProps';
import { linkCustomer, LinkCustomerLinkType } from './helpers';
import getContractSentEventProps from './contract/getContractSentEventProps';
import getContractExecutedEventProps from './contract/getContractExecutedEventProps';
import getContractResentEventProps from './contract/getContractResentEventProps';
import getContractResentToCountersignerEventProps from './contract/getContractResentToCountersignerEventProps';
import getContractApprovedEventProps from './contract/getContractApprovedEventProps';
import getContractSignedByCustomerEventProps from './contract/getContractSignedByCustomerEventProps';
import getContractSignedByPlannerEventProps from './contract/getContractSignedByPlannerEventProps';
import getContractSignedByCountersignerEventProps from './contract/getContractSignedByCountersignerEventProps';
import getContractDueSoonEventProps from './contract/getContractDueSoonEventProps';
import getContractViewedEventProps from './contract/getContractViewedEventProps';
import { LinkObjectType } from '../../lib/helpers/types';
import getProposalViewedEventProps from './proposal/getProposalViewedEventProps';
import { EventCategory } from '../../types/generated';
import getContractPastDueEventProps from './contract/getContractPastDueEventProps';
import quickPaymentPaidEventProps from './quickPayment/quickPaymentPaidEventProps';
import { displayStripeNeedsModal } from '../../redux/actions';
import { getPaymentInstallmentNames } from './helpers';
import Link from 'next/link';
import { notEmpty } from '../../lib/helpers/apollo';
import { LoadMore } from './LoadMore';
import { PremiumSubscriptionRequiredModal } from '../PremiumSubscriptionRequiredModal';
import { LinkButton } from '../LinkButton';
import { getContractPastDueForCounterSigningEventProps } from './contract/getContractPastDueForCountersigningEventProps';

const EventFeed = ( props ) => {
	const dispatch = useDispatch();
	const user = useSelector( ( state ) => state.user );

	const [ openPremiumSubscriptionRequiredModal, setPremiumSubscriptionRequiredModalOpen, ] = useState( false );

	const resendInvoice = async ( id ) => {
		const { success, errors } = await API.resendInvoice( id );

		if ( errors ) {
			if (
				errors.some( ( error ) =>
					error.message.includes( rpcShared.strings.errorMessages.hasStripeNeeds )
				)
			) {
				dispatch(
					displayStripeNeedsModal( { active: true, props: { purpose: null } } )
				);
			} else {
				errors.map( ( error ) => showError( error ) );
			}
			return;
		}

		if ( success ) {
			showSuccess();
			if ( props.refresh ) {
				return props.refresh();
			}
		}
	};

	const resendContract = async ( id ) => {
		const { success, errors } = await API.resendContract( id );

		if ( errors ) {
			errors.map( ( error ) => showError( error ) );
			return;
		}

		if ( success ) {
			showSuccess();
			if ( props.refresh ) {
				return props.refresh();
			}
		}
	};

	const activateContact = async ( { id } ) => {
		const { contact, errors } = await API.activateContact( id );
		if ( errors ) {
			errors.map( ( error ) => showError( error ) );
			return;
		}

		if ( contact.hasCustomerIntent ) {
			showSuccess( 'Connection accepted!' );
			if ( props.refresh ) {
				return props.refresh();
			}
		}
	};

	const declineContact = async ( event, contactShouldBeCancelled ) => {
		const { contact } = event;

		const { success, errors } = contactShouldBeCancelled
			? await API.cancelContact( contact.id )
			: await API.declineContact( contact.id );
		if ( errors ) {
			errors.map( ( error ) => showError( error ) );
			return;
		}

		if ( success ) {
			showSuccess( 'Connection Declined.' );
			if ( props.refresh ) {
				return props.refresh();
			}
		}
	};

	const getEventRow = ( event ) => {
		if ( !user?.isLoggedIn ) {
			return false;
		}
		let eventProps = {
			event,
			message: null,
			links: null,
			icon: '',
			className: `table__row--activity-feed--${ event.category }`,
		};

		let thirdPartyName,
			thirdPartyObj,
			hasPayingPermission,
			hasConnectionPermissions;

		switch ( event.category ) {
			case EventCategory.UserInvitedAsClient:
				eventProps.icon = 'send';
				if ( event.contact.firstName && event.contact.lastName ) {
					eventProps.message = (
						<Typography>
							You invited { event.contact.firstName } { event.contact.lastName } to
							join RPC!
						</Typography>
					);
				} else {
					eventProps.message = (
						<Typography>
							You invited{ ' ' }
							{ toSimpleLink( {
								link: { href: `mailto:${ event.invitation.emailAddress }` },
								text: event.invitation.emailAddress,
							} ) }{ ' ' }
							to join RPC!
						</Typography>
					);
				}
				break;
			case EventCategory.UserInvitedAsOrganization:
			case EventCategory.UserInvitedAsOrgMember:
				eventProps.icon = 'send';
				if (
					event.invitation.recipientFirstName &&
					event.invitation.recipientLastName
				) {
					eventProps.message = (
						<Typography>
							You invited { event.invitation.recipientFirstName }{ ' ' }
							{ event.invitation.recipientLastName } to join RPC!
						</Typography>
					);
				} else {
					eventProps.message = (
						<Typography>
							You invited{ ' ' }
							{ toSimpleLink( {
								link: { href: `mailto:${ event.invitation.emailAddress }` },
								text: event.invitation.emailAddress,
							} ) }{ ' ' }
							to join RPC!
						</Typography>
					);
				}
				break;
			case EventCategory.UserInvitedAsOrganizationByPlannerOnBehalfOfClient:
				eventProps.icon = 'send';
				if ( isAssignedPlannerOrAdmin( user, event.clientUser ) ) {
					if (
						event.invitation.recipientFirstName &&
						event.invitation.recipientLastName
					) {
						eventProps.message = (
							<Typography>
								You invited { event.invitation.recipientFirstName }{ ' ' }
								{ event.invitation.recipientLastName } to connect with{ ' ' }
								{ linkCustomer( event ) }!
							</Typography>
						);
					} else {
						eventProps.message = (
							<Typography>
								You invited{ ' ' }
								{ toSimpleLink( {
									link: { href: `mailto:${ event.invitation.emailAddress }` },
									text: event.invitation.emailAddress,
								} ) }{ ' ' }
								to connect with { linkCustomer( event ) }!
							</Typography>
						);
					}
				} else {
					if (
						event.invitation.recipientFirstName &&
						event.invitation.recipientLastName
					) {
						eventProps.message = (
							<Typography>
								{ linkObject( event.plannerUser.organization ) } invited{ ' ' }
								{ event.invitation.recipientFirstName }{ ' ' }
								{ event.invitation.recipientLastName } to join RPC!
							</Typography>
						);
					} else {
						eventProps.message = (
							<Typography>
								{ linkObject( event.plannerUser.organization ) } invited{ ' ' }
								{ toSimpleLink( {
									link: { href: `mailto:${ event.invitation.emailAddress }` },
									text: event.invitation.emailAddress,
								} ) }{ ' ' }
								to join RPC!
							</Typography>
						);
					}
				}
				break;
			case EventCategory.VendorRequestedContactActivation:
			case EventCategory.ConnectionRequestedByVendor:
				eventProps.icon = 'link';

				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							{ linkObject( event.vendor ) } wants to connect with you!
						</Typography>
					);
					if ( !eventProps.event.resolvedBy ) {
						eventProps.links = [
							{
								type: 'secondary',
								text: 'Decline',
								onClick: () => declineContact( event ),
							},
							{
								type: 'primary',
								text: 'Accept',
								onClick: () => activateContact( event.contact ),
							},
						];
					}
				}

				if ( isOrgUser( user ) && event.orgUser.id === user.id ) {
					eventProps.message = (
						<Typography>Invitation sent to { linkCustomer( event ) }!</Typography>
					);
				}

				if ( isAssignedPlannerOrAdmin( user, event.clientUser ) ) {
					hasConnectionPermissions =
						event.clientUser?.plannerOrgHasConnectionPermissions;

					eventProps.message = (
						<Typography>
							{ linkObject( event.vendor ) } wants to connect with your client,{ ' ' }
							{ linkCustomer( event ) }.
						</Typography>
					);
					if (
						event.vendor.id !== user.organization.id &&
						hasConnectionPermissions &&
						!eventProps.event.resolvedBy
					) {
						eventProps.links = [
							{
								type: 'secondary',
								text: 'Decline',
								onClick: () => declineContact( event ),
							},
							{
								type: 'primary',
								text: 'Accept',
								onClick: () => activateContact( event.contact ),
							},
						];
					}
				}

				break;
			case EventCategory.ConnectionRequestApprovedByClient:
			case EventCategory.ConnectionRequestApprovedByVendor:
			case EventCategory.ConnectionRequestApprovedByPlannerOnBehalfOfClient:
			case EventCategory.ConnectionRequestedByClient:
			case EventCategory.ConnectionRequestedByPlannerOnBehalfOfClient:
			case EventCategory.ConnectionPendingFromInviteByClient:
			case EventCategory.ContactCreatedByClient:
			case EventCategory.ContactCreatedByPlannerOboClient:
				eventProps.icon = 'connected';
				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					thirdPartyObj = event.vendor;
					thirdPartyName = thirdPartyObj.name;
				} else if ( isOrgUser( user ) ) {
					thirdPartyObj = event.clientUser || event.orgUser;
					( { fullName: thirdPartyName } = rpcShared.contacts.getCustomerName(
						event.contact
					) );
				}
				eventProps.message = (
					<Typography>
						You are now connected with{ ' ' }
						{ toSimpleLink( {
							link: getDetailLink( { object: thirdPartyObj } ),
							text: thirdPartyName,
						} ) }
						!
					</Typography>
				);
				if (
					isAssignedPlannerOrAdmin( user, event.clientUser ) &&
					event.orgUser.id !== user.id
				) {
					eventProps.message = (
						<Typography>
							Your client{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( { object: thirdPartyObj } ),
								text: thirdPartyName,
							} ) }{ ' ' }
							is now connected to{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.vendor,
									type: LinkObjectType.Organization,
								} ),
								text: event.vendor.name,
							} ) }
							.
						</Typography>
					);
				}
				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( { object: thirdPartyObj } ),
					},
				];
				break;
			case EventCategory.ConnectionPendingFromInviteByVendor:
				eventProps.icon = 'link';
				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					thirdPartyObj = event.vendor;
					thirdPartyName = thirdPartyObj.name;
				} else if ( isOrgUser( user ) ) {
					thirdPartyObj = event.clientUser;
					( { fullName: thirdPartyName } = rpcShared.contacts.getCustomerName(
						event.contact
					) );
				}
				eventProps.message = (
					<Typography>
						Your connection with{ ' ' }
						{ toSimpleLink( {
							link: getDetailLink( { object: thirdPartyObj } ),
							text: thirdPartyName,
						} ) }{ ' ' }
						is pending!
					</Typography>
				);
				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( { object: thirdPartyObj } ),
					},
				];
				break;
			case EventCategory.InvoiceResent:
			case EventCategory.InvoiceSent:
			case EventCategory.InvoiceSentAwaiting:
				eventProps.icon = 'invoice';
				const isResentEvent = event.category === EventCategory.InvoiceResent;

				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					isResentEvent
						? ( eventProps.message = (
							<Typography>
								{ toSimpleLink( {
									link: getDetailLink( {
										object: event.invoice,
										type: LinkObjectType.Invoice,
									} ),
									text: event.invoice.title,
								} ) }{ ' ' }
									has been resent by { linkObject( event.vendor ) }.
							</Typography>
						  ) )
						: ( eventProps.message = (
							<Typography className='invoice-sent__invoice'>
									New{ ' ' }
								{ toSimpleLink( {
									link: getDetailLink( { object: event.invoice } ),
									text: 'invoice',
								} ) }{ ' ' }
									from { linkObject( event.vendor ) }.
							</Typography>
						  ) );

					eventProps.links = [
						{
							type: event.resolvedBy ? 'secondary' : 'primary',
							text: event.resolvedBy ? 'View' : 'Pay',
							link: getDetailLink( {
								object: event.invoice,
								type: LinkObjectType.Invoice,
							} ),
							className: 'eventRow-new-invoice__pay-btn',
						},
					];
				} else if ( isAssignedPlannerOrAdmin( user, event.clientUser ) ) {
					hasPayingPermission = event.clientUser?.plannerOrgCanPayInvoices;
					// Viewing assigned client's profile
					if ( props.targetUser?.user.userType === 'ClientUser' ) {
						isResentEvent
							? ( eventProps.message = (
								<Typography>
									{ toSimpleLink( {
										link: getDetailLink( {
											object: event.invoice,
											type: LinkObjectType.Invoice,
										} ),
										text: event.invoice.title,
									} ) }{ ' ' }
										has been resent by { linkObject( event.vendor ) }.
								</Typography>
							  ) )
							: ( eventProps.message = (
								<Typography>
										New{ ' ' }
									{ toSimpleLink( {
										link: getDetailLink( {
											object: event.invoice,
											type: LinkObjectType.Invoice,
										} ),
										text: 'invoice',
									} ) }{ ' ' }
										from { linkObject( event.vendor ) }.
								</Typography>
							  ) );
						// Is in org that sent invoice
						if ( event.vendor.id === user.organization.id ) {
							eventProps.links = [
								{
									type: 'secondary',
									text: 'View',
									link: getDetailLink( {
										object: event.invoice,
										type: LinkObjectType.Invoice,
									} ),
								},
							];
							// Is not in org that sent invoice
						} else {
							eventProps.links = [
								{
									type:
										event.resolvedBy || !hasPayingPermission
											? 'secondary'
											: 'primary',
									text:
										event.resolvedBy || !hasPayingPermission ? 'View' : 'Pay',
									link: getDetailLink( {
										object: event.invoice,
										type: LinkObjectType.Invoice,
									} ),
								},
							];
						}
						// Viewing homepage feed
					} else {
						// Is in org that sent invoice
						if ( event.vendor.id === user.organization.id ) {
							isResentEvent
								? ( eventProps.message = (
									<Typography>
											Your organization resent { linkCustomer( event ) } an invoice:{ ' ' }
										{ linkObject( event.invoice ) }.
									</Typography>
								  ) )
								: ( eventProps.message = (
									<Typography>
											Your organization sent { linkCustomer( event ) } an invoice:{ ' ' }
										{ linkObject( event.invoice ) }.
									</Typography>
								  ) );
							eventProps.links = [
								{
									type: 'secondary',
									text: 'View',
									link: getDetailLink( {
										object: event.invoice,
										type: LinkObjectType.Invoice,
									} ),
								},
							];
							// Is not in org that sent invoice
						} else {
							isResentEvent
								? ( eventProps.message = (
									<Typography>
										{ linkObject( event.vendor ) } resent an invoice to{ ' ' }
										{ linkCustomer( event ) }:{ ' ' }
										{ toSimpleLink( {
											link: getDetailLink( {
												object: event.invoice,
												baseUserId: event.clientUser.user.id,
												type: LinkObjectType.Invoice,
											} ),
											text: event.invoice.title,
										} ) }
											.
									</Typography>
								  ) )
								: ( eventProps.message = (
									<Typography>
										{ linkObject( event.vendor ) } sent a new invoice to{ ' ' }
										{ linkCustomer( event ) }:{ ' ' }
										{ toSimpleLink( {
											link: getDetailLink( {
												object: event.invoice,
												baseUserId: event.clientUser.user.id,
												type: LinkObjectType.Invoice,
											} ),
											text: event.invoice.title,
										} ) }
											.
									</Typography>
								  ) );

							eventProps.links = [
								{
									type:
										event.resolvedBy || !hasPayingPermission
											? 'secondary'
											: 'primary',
									text:
										event.resolvedBy || !hasPayingPermission ? 'View' : 'Pay',
									link: getDetailLink( {
										object: event.invoice,
										type: LinkObjectType.Invoice,
									} ),
								},
							];
						}
						break;
					}
				} else if ( isOrgUser( user ) ) {
					isResentEvent
						? ( eventProps.message = (
							<Typography>
									You resent{ ' ' }
								{ toSimpleLink( {
									link: getDetailLink( {
										object: event.invoice,
										type: LinkObjectType.Invoice,
									} ),
									text: event.invoice.title,
								} ) }{ ' ' }
									to { linkCustomer( event, LinkCustomerLinkType.Contact ) }.
							</Typography>
						  ) )
						: ( eventProps.message = (
							<Typography>
									You sent { linkCustomer( event, LinkCustomerLinkType.Contact ) }{ ' ' }
									an invoice: { linkObject( event.invoice ) }.
							</Typography>
						  ) );
					eventProps.links = [
						{
							type: 'secondary',
							text: 'View',
							link: getDetailLink( {
								object: event.invoice,
								type: LinkObjectType.Invoice,
							} ),
						},
					];
					break;
				}
				break;
			case EventCategory.InvoiceApproved:
				eventProps.icon = 'invoice';
				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							{ linkObject( event.orgUser.organization ) } approved{ ' ' }
							{ linkObject( event.invoice.contact.vendor ) }'s invoice.
						</Typography>
					);
					eventProps.links = [
						{
							type: event.resolvedBy ? 'secondary' : 'primary',
							text: event.resolvedBy ? 'View' : 'Pay',
							link: getDetailLink( {
								object: event.invoice,
								type: LinkObjectType.Invoice,
							} ),
						},
					];
				}
				break;
			case EventCategory.InvoiceViewed:
				eventProps.message = (
					<Typography>
						{ linkObject( event.invoice ) } has been viewed by{ ' ' }
						{ linkCustomer( event, LinkCustomerLinkType.Contact ) }
					</Typography>
				);

				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							You viewed { linkObject( event.invoice ) } from{ ' ' }
							{ linkObject( event.vendor ) }
						</Typography>
					);
				}

				eventProps.icon = 'invoice';
				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.invoice,
							type: LinkObjectType.Invoice,
						} ),
					},
				];
				break;
			case EventCategory.InvoiceFullyPaid:
				eventProps.icon = 'dollar';
				eventProps.message = (
					<Typography>
						Done! { linkObject( event.invoice ) } has been paid in full!
					</Typography>
				);
				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.invoice,
							type: LinkObjectType.Invoice,
						} ),
					},
				];
				break;
			case EventCategory.InstallmentPaymentDueSoon:
				eventProps.icon = 'invoice-due';

				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							A payment for { linkObject( event.invoice ) } is due in 1 week,{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
								} ),
								text: 'click here',
							} ) }{ ' ' }
							to take care of it now!
						</Typography>
					);
					eventProps.links = [
						{
							type: event.resolvedBy ? 'secondary' : 'primary',
							text: event.resolvedBy ? 'View' : 'Pay',
							link: getDetailLink( {
								object: event.invoice,
								type: LinkObjectType.Invoice,
							} ),
						},
					];
				} else {
					eventProps.message = (
						<Typography>
							{ linkCustomer( event, LinkCustomerLinkType.Contact ) } has a payment
							due for { linkObject( event.invoice ) } in 1 week. No worries, we sent
							them a reminder too!
						</Typography>
					);
					eventProps.links = [
						{
							type: 'secondary',
							text: 'View',
							link: getDetailLink( {
								object: event.invoice,
								type: LinkObjectType.Invoice,
							} ),
						},
					];
				}
				break;
			case EventCategory.InstallmentPaymentRequestedByPlanner:
				eventProps.icon = 'dollar';
				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.invoice,
							type: LinkObjectType.Invoice,
						} ),
					},
				];

				// Client and sending org's owner see this:
				eventProps.message = (
					<Typography>
						{ linkObject( event.plannerUser ) } has paid{ ' ' }
						{ getPaymentInstallmentNames( event.paymentInstallments ) } on{ ' ' }
						{ linkObject( event.invoice ) }.
					</Typography>
				);

				// Sender of invoice sees this:
				if ( isOrgUser( user ) && user.id === event.orgUser.id ) {
					eventProps.message = (
						<Typography>
							{ linkCustomer( event ) }'s planner has paid{ ' ' }
							{ getPaymentInstallmentNames( event.paymentInstallments ) } on{ ' ' }
							{ linkObject( event.invoice ) }.
						</Typography>
					);
				}

				break;
			case EventCategory.InstallmentPaymentRequestedByClient:
			case EventCategory.InstallmentPaymentRequestedByGuest:
				eventProps.icon = 'dollar';

				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							You paid { getPaymentInstallmentNames( event.paymentInstallments ) }{ ' ' }
							on { linkObject( event.invoice ) }.
						</Typography>
					);
					eventProps.links = [
						{
							type: 'secondary',
							text: 'View',
							link: getDetailLink( {
								object: event.invoice,
								type: LinkObjectType.Invoice,
							} ),
						},
					];
					break;
				}

				if ( isOrgUser( user ) ) {
					eventProps.message = (
						<Typography>
							{ linkCustomer( event, LinkCustomerLinkType.Contact ) } has paid{ ' ' }
							{ getPaymentInstallmentNames( event.paymentInstallments ) } on{ ' ' }
							{ linkObject( event.invoice ) }.
						</Typography>
					);
					eventProps.links = [
						{
							type: 'secondary',
							text: 'View',
							link: getDetailLink( {
								object: event.invoice,
								type: LinkObjectType.Invoice,
							} ),
						},
					];
				}
				break;
			case EventCategory.InstallmentPaymentRequestedBySchedule:
				eventProps.icon = 'dollar';
				if ( isOrgUser( user ) ) {
					eventProps.message = (
						<Typography>
							{ linkCustomer( event, LinkCustomerLinkType.Contact ) } has paid an
							installment on { linkObject( event.invoice ) }
						</Typography>
					);
					eventProps.links = [
						{
							type: 'secondary',
							text: 'View',
							link: getDetailLink( {
								object: event.invoice,
								type: LinkObjectType.Invoice,
							} ),
						},
					];
				}
				break;
			case EventCategory.InstallmentPaymentRecordedOfflineByVendor:
				eventProps.icon = 'dollar';
				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							Woot! { linkObject( event.orgUser.organization ) } recorded a cash or
							check payment for you. Click{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
								} ),
								text: 'here',
							} ) }{ ' ' }
							to view the invoice.
						</Typography>
					);
				} else {
					// if you're a customer then you're not these other things
					if ( isOrgUser( user ) ) {
						eventProps.message = (
							<Typography>
								A cash or check payment has been recorded for{ ' ' }
								{ linkCustomer( event, LinkCustomerLinkType.Contact ) }. Click{ ' ' }
								{ toSimpleLink( {
									link: getDetailLink( {
										object: event.invoice,
										type: LinkObjectType.Invoice,
									} ),
									text: 'here',
								} ) }{ ' ' }
								to view the invoice.
							</Typography>
						);
					}

					if ( isAssignedPlannerOrAdmin( user, event.clientUser ) ) {
						eventProps.message = (
							<Typography>
								{ linkObject( event.orgUser.organization ) } recorded a cash or
								check payment for { linkCustomer( event ) }. Click{ ' ' }
								{ toSimpleLink( {
									link: getDetailLink( {
										object: event.invoice,
										type: LinkObjectType.Invoice,
									} ),
									text: 'here',
								} ) }{ ' ' }
								to view the invoice.
							</Typography>
						);
					}
				}
				break;
			case EventCategory.InstallmentPaymentUpdatedOfflineByVendor:
				eventProps.icon = 'dollar';
				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							{ linkObject( event.orgUser.organization ) } updated a cash or check
							payment for you. Click{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
								} ),
								text: 'here',
							} ) }{ ' ' }
							to view the invoice.
						</Typography>
					);
				} else if ( isAssignedPlannerOrAdmin( user, event.clientUser ) ) {
					eventProps.message = (
						<Typography>
							{ linkObject( event.orgUser.organization ) } updated a cash or check
							payment for { linkCustomer( event ) }. Click{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
								} ),
								text: 'here',
							} ) }{ ' ' }
							to view the invoice.
						</Typography>
					);
				} else if ( isOrgUser( user ) ) {
					eventProps.message = (
						<Typography>
							A cash or check payment has been updated for{ ' ' }
							{ linkCustomer( event, LinkCustomerLinkType.Contact ) }. Click{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
								} ),
								text: 'here',
							} ) }{ ' ' }
							to view the invoice.
						</Typography>
					);
				}

				break;
			case EventCategory.InstallmentRefundUpdatedOfflineByVendor:
				eventProps.icon = 'dollar';
				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							{ linkObject( event.orgUser.organization ) } recorded a cash or check
							refund for you. Click{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
								} ),
								text: 'here',
							} ) }{ ' ' }
							to view the invoice.
						</Typography>
					);
				} else if ( isAssignedPlannerOrAdmin( user, event.clientUser ) ) {
					eventProps.message = (
						<Typography>
							{ linkObject( event.orgUser.organization ) } recorded a cash or check
							refund for { linkCustomer( event ) }. Click{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
								} ),
								text: 'here',
							} ) }{ ' ' }
							to view the invoice.
						</Typography>
					);
				} else if ( isOrgUser( user ) ) {
					eventProps.message = (
						<Typography>
							A cash or check refund has been recorded for{ ' ' }
							{ linkCustomer( event, LinkCustomerLinkType.Contact ) }. Click{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
								} ),
								text: 'here',
							} ) }{ ' ' }
							to view the invoice.
						</Typography>
					);
				}

				break;
			case EventCategory.InstallmentRefundRecordedOfflineByVendor:
				eventProps.icon = 'dollar';
				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							{ linkObject( event.orgUser.organization ) } refunded your cash or
							check payment. Click{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
								} ),
								text: 'here',
							} ) }{ ' ' }
							to view the invoice. Please check with your vendor about
							processing time for refunds.
						</Typography>
					);
				} else if ( isAssignedPlannerOrAdmin( user, event.clientUser ) ) {
					eventProps.message = (
						<Typography>
							{ linkObject( event.orgUser.organization ) } refunded a cash or check
							payment for { linkCustomer( event ) }. Click{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
								} ),
								text: 'here',
							} ) }{ ' ' }
							to view the invoice.
						</Typography>
					);
				} else if ( isOrgUser( user ) ) {
					eventProps.message = (
						<Typography>
							A cash or check refund has been recorded for{ ' ' }
							{ linkCustomer( event, LinkCustomerLinkType.Contact ) }. Click{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
								} ),
								text: 'here',
							} ) }{ ' ' }
							to view the invoice. Please work with your client directly to
							process this offline refund.
						</Typography>
					);
				}

				break;
			case EventCategory.InstallmentPaymentFailed:
				eventProps.icon = 'warning';
				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.invoice,
							type: LinkObjectType.Invoice,
						} ),
					},
				];

				eventProps.message = (
					<Typography>
						Your payment didn't process, please try that again!
					</Typography>
				);

				if ( isAssignedPlannerOrAdmin( user, event.clientUser ) ) {
					eventProps.message = (
						<Typography>
							A Payment failed for an invoice belonging to{ ' ' }
							{ linkCustomer( event, LinkCustomerLinkType.Contact ) }:{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
									baseUserId: event.clientUser.user.id,
								} ),
								text: event.invoice.title,
							} ) }
							.
						</Typography>
					);
					break;
				}

				break;
			case EventCategory.InstallmentRefundFailed:
				eventProps.icon = 'warning';
				if ( isOrgUser( user ) ) {
					/* eslint-disable-next-line */
					const message = `Uh oh, unable to process refund for ${event.paymentInstallments[0].name} on ${event.invoice.title}.`;
					eventProps.message = (
						<Typography>
							{ message } Please contact{ ' ' }
							{ toSimpleLink( {
								link: { href: 'mailto:hello@rockpapercoin.com' },
								text: 'your RPC Administrator.',
							} ) }
						</Typography>
					);
					eventProps.links = [
						{
							type: 'secondary',
							text: 'View',
							link: getDetailLink( {
								object: event.invoice,
								type: LinkObjectType.Invoice,
							} ),
						},
					];
					break;
				}
				break;
			case EventCategory.InvoicePastDue:
				eventProps.icon = 'invoice-due';
				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.invoice,
							type: LinkObjectType.Invoice,
						} ),
					},
				];
				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							{ linkObject( event.invoice ) } from { linkObject( event.vendor ) } is
							past due.
						</Typography>
					);
				} else if (
					// you are the client's planner, but not the vendor on document
					// this will override the previous isOrgUser
					isAssignedPlannerOrAdmin( user, event.clientUser ) &&
					event.orgUser.id !== user.id
				) {
					eventProps.message = (
						<Typography>
							An invoice from { linkObject( event.vendor ) } that was sent to{ ' ' }
							{ linkCustomer( event ) } is past due:{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
									baseUserId: event.clientUser.user.id,
								} ),
								text: event.invoice.title,
							} ) }
							.
						</Typography>
					);
					eventProps.links = [
						{
							type: 'secondary',
							text: 'View',
							link: getDetailLink( {
								object: event.invoice,
								type: LinkObjectType.Invoice,
							} ),
						},
					];
				} else if ( isOrgUser( user ) ) {
					// you are a member of a vendor or planner org
					eventProps.message = (
						<Typography>
							{ linkObject( event.invoice ) } is past due. Resend?
						</Typography>
					);
					eventProps.links = [
						{
							type: event.resolvedBy ? 'secondary' : 'primary',
							text: event.resolvedBy ? 'View' : 'Resend',
							link: event.resolvedBy
								? getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
								  } )
								: null,
							onClick: event.resolvedBy
								? null
								: () => resendInvoice( event.invoice.id ),
						},
					];
				}

				break;
			case EventCategory.InstallmentRefundRequested: // refund being processed
				eventProps.icon = 'dollar';
				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.invoice,
							type: LinkObjectType.Invoice,
						} ),
					},
				];

				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							Your refund from { linkObject( event.vendor ) } is being processed.
						</Typography>
					);
				}

				if ( isAssignedPlannerOrAdmin( user, event.clientUser ) ) {
					eventProps.message = (
						<Typography>
							{ linkCustomer( event ) }'s refund from { linkObject( event.vendor ) } is
							being processed.
						</Typography>
					);
					eventProps.links[ 0 ].link = getDetailLink( {
						object: event.invoice,
						type: LinkObjectType.Invoice,
						baseUserId: event.clientUser.id,
					} );
				}

				break;
			case EventCategory.InstallmentRefundCompleted:
				eventProps.icon = 'dollar';
				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.invoice,
							type: LinkObjectType.Invoice,
						} ),
					},
				];

				if (
					rpcShared.contacts.isCustomer( event.contact, user.user.id ) ||
					isAssignedPlannerOrAdmin( user, event.clientUser )
				) {
					eventProps.message = (
						<Typography>
							A refund has been made on { linkObject( event.invoice ) }.
						</Typography>
					);
				} else if ( isOrgUser( user ) ) {
					eventProps.message = (
						<Typography>
							Your refund for { linkObject( event.invoice ) } has been processed.
						</Typography>
					);
				}

				break;
			case EventCategory.ContractSent:
			case EventCategory.ContractSentAwaiting:
				eventProps = getContractSentEventProps( eventProps, user );
				break;
			case EventCategory.ContractResent:
				eventProps = getContractResentEventProps( eventProps, user );
				break;
			case EventCategory.ContractExecuted:
				eventProps = getContractExecutedEventProps( eventProps, user );
				break;
			case EventCategory.ContractResentToCountersigner:
				eventProps = getContractResentToCountersignerEventProps(
					eventProps,
					user
				);
				break;
			case EventCategory.ContractApproved:
				eventProps = getContractApprovedEventProps( eventProps, user );
				break;
			case EventCategory.ContractSignedByCustomer:
				eventProps = getContractSignedByCustomerEventProps( eventProps, user );
				break;
			case EventCategory.ContractSignedByPlanner:
				eventProps = getContractSignedByPlannerEventProps( eventProps, user );
				break;
			case EventCategory.ContractSignedByCountersigner:
				eventProps = getContractSignedByCountersignerEventProps(
					eventProps,
					user
				);
				break;
			case EventCategory.ContractDueSoon:
				eventProps = getContractDueSoonEventProps( {
					eventProps,
					user: user,
					resendContract,
				} );
				break;
			case EventCategory.ContractPastDue:
				eventProps = getContractPastDueEventProps( {
					eventProps,
					user: user,
				} );
				break;
			case EventCategory.ContractPastDueForCountersigning:
				eventProps = getContractPastDueForCounterSigningEventProps( {
					eventProps,
					user,
				} );
				break;
			case EventCategory.ContractViewed:
				eventProps = getContractViewedEventProps( eventProps, user );
				break;
			case EventCategory.ApplicationBillingProcessed: // subscription processed
				eventProps.icon = 'dollar';
				eventProps.message = (
					<Typography>Subscription payment has been processed!</Typography>
				);
				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: { href: '/admin' },
					},
				];
				break;
			case EventCategory.ApplicationBillingActionRequired:
				eventProps.icon = 'dollar';
				eventProps.message = (
					<Typography>Whoops. Annual payment method needs updating.</Typography>
				);
				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: { href: '/admin' },
					},
				];
				break;
			case EventCategory.ClientGrantedPlannerSigningPermission:
				eventProps.icon = 'edit';

				eventProps.message = (
					<Typography>
						{ linkCustomer( event ) } has enabled you to sign contracts on their
						behalf.
					</Typography>
				);

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.clientUser.user,
							type: LinkObjectType.Contact,
						} ),
					},
				];
				break;
			case EventCategory.ClientGrantedPlannerPayingPermission:
				eventProps.icon = 'edit';

				eventProps.message = (
					<Typography>
						{ linkCustomer( event ) } has enabled you to pay invoices on their
						behalf.
					</Typography>
				);

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.clientUser.user,
							type: LinkObjectType.Contact,
						} ),
					},
				];
				break;
			case EventCategory.ClientGrantedPlannerConnectionsPermission:
				eventProps.icon = 'edit';

				eventProps.message = (
					<Typography>
						{ linkCustomer( event ) } has enabled you to accept connections on their
						behalf.
					</Typography>
				);

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.clientUser.user,
							type: LinkObjectType.Contact,
						} ),
					},
				];
				break;
			case EventCategory.ClientRevokedPlannerSigningPermission:
				eventProps.icon = 'decline';

				eventProps.message = (
					<Typography>
						{ linkCustomer( event ) } has disallowed you from signing contracts on
						their behalf.
					</Typography>
				);

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.clientUser.user,
							type: LinkObjectType.Contact,
						} ),
					},
				];
				break;
			case EventCategory.ClientRevokedPlannerPayingPermission:
				eventProps.icon = 'decline';

				eventProps.message = (
					<Typography>
						{ linkCustomer( event ) } has disallowed you from paying invoices on
						their behalf.
					</Typography>
				);

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.clientUser.user,
							type: LinkObjectType.Contact,
						} ),
					},
				];
				break;
			case EventCategory.ClientRevokedPlannerConnectionsPermission:
				eventProps.icon = 'decline';

				eventProps.message = (
					<Typography>
						{ linkCustomer( event ) } has disallowed you from accepting connections
						on their behalf.
					</Typography>
				);

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.clientUser.user,
							type: LinkObjectType.Contact,
						} ),
					},
				];
				break;
			case EventCategory.UserJoinedOrganization:
				eventProps.icon = 'connected';

				eventProps.message = (
					<Typography>
						{ linkObject( event.orgUser ) } has joined { linkObject( event.vendor ) }.
					</Typography>
				);

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.orgUser.user,
							type: LinkObjectType.Contact,
						} ),
					},
				];
				break;
			case EventCategory.PayoutFailed:
				eventProps.icon = 'warning';
				const payoutFailedCopy =
					'We attempted to deposit money into your bank account,' +
					' but couldn’t proceed. Our payment processor, Stripe, reports ';
				switch ( event.message ) {
					case 'account_closed':
						eventProps.message = (
							<Typography>
								{ payoutFailedCopy }the bank account has been closed. Please visit{ ' ' }
								{ toSimpleLink( { link: { href: '/wallet' }, text: 'My Wallet' } ) }{ ' ' }
								to update your account.
							</Typography>
						);
						break;
					case 'account_frozen':
						eventProps.message = (
							<Typography>
								{ payoutFailedCopy }the bank account has been frozen. Please visit{ ' ' }
								{ toSimpleLink( { link: { href: '/wallet' }, text: 'My Wallet' } ) }{ ' ' }
								to update your account.
							</Typography>
						);
						break;
					case 'bank_account_restricted':
						eventProps.message = (
							<Typography>
								{ payoutFailedCopy }the bank account is restricted. Please visit{ ' ' }
								{ toSimpleLink( { link: { href: '/wallet' }, text: 'My Wallet' } ) }{ ' ' }
								to update your account.
							</Typography>
						);
						break;
					case 'bank_ownership_changed':
						eventProps.message = (
							<Typography>
								{ payoutFailedCopy }the bank branch is no longer valid. Please
								visit{ ' ' }
								{ toSimpleLink( { link: { href: '/wallet' }, text: 'My Wallet' } ) }{ ' ' }
								to update your account.
							</Typography>
						);
						break;
					case 'could_not_process':
						eventProps.message = (
							<Typography>
								{ payoutFailedCopy }it could not be processed by the bank. Please
								contact your bank for further information.
							</Typography>
						);
						break;
					case 'debit_not_authorized':
						eventProps.message = (
							<Typography>
								{ payoutFailedCopy }a debit account was used. Please visit{ ' ' }
								{ toSimpleLink( { link: { href: '/wallet' }, text: 'My Wallet' } ) }{ ' ' }
								to add a bank account.
							</Typography>
						);
						break;
					case 'declined':
						eventProps.message = (
							<Typography>
								{ payoutFailedCopy }that the bank declined the transfer. Please
								contact your bank for further information.
							</Typography>
						);
						break;
					case 'invalid_account_number':
						eventProps.message = (
							<Typography>
								{ payoutFailedCopy }the bank account number is invalid. Please
								visit{ ' ' }
								{ toSimpleLink( { link: { href: '/wallet' }, text: 'My Wallet' } ) }{ ' ' }
								to update your bank account information.
							</Typography>
						);
						break;
					case 'incorrect_account_holder_name':
						eventProps.message = (
							<Typography>
								{ payoutFailedCopy }the bank account holder name is incorrect.
								Please visit{ ' ' }
								{ toSimpleLink( { link: { href: '/wallet' }, text: 'My Wallet' } ) }{ ' ' }
								to update your information.
							</Typography>
						);
						break;
					case 'invalid_currency':
						eventProps.message = (
							<Typography>
								{ payoutFailedCopy }the bank was unable to process because of the
								currency used. Please contact your bank for further information.
							</Typography>
						);
						break;
					case 'no_account':
						eventProps.message = (
							<Typography>
								{ payoutFailedCopy }the bank account details are incorrect. Please
								update your account information in{ ' ' }
								{ toSimpleLink( { link: { href: '/wallet' }, text: 'My Wallet' } ) }
								.
							</Typography>
						);
						break;
					case 'unsupported_card':
						eventProps.message = (
							<Typography>
								{ payoutFailedCopy }the bank no longer supports payouts to this
								card. Please contact your bank for further information.
							</Typography>
						);
						break;
				}
				break;
			case EventCategory.PayoutWithdrawalFailed:
				eventProps.icon = 'warning';
				const stripePayoutObject = JSON.parse( event.message );
				eventProps.message =
					stripePayoutObject.failure_code === 'insufficient_funds' ? (
						<Typography>
							Our payment processor, Stripe, reports the account has
							insufficient funds. Please ensure your account has the necessary
							funds to cover the withdrawal of{ ' ' }
							{ rpcShared.invoices.numberToCurrency(
								// make positive to make sense with the verbiage of "withdrawal of"
								stripePayoutObject.amount * -1
							) }
						</Typography>
					) : (
						<Typography>
							Our payment processor, Stripe, reports there is an issue with your
							account. Please update your “Pay To” account in the{ ' ' }
							{ toSimpleLink( { link: { href: '/wallet' }, text: 'My Wallet' } ) }{ ' ' }
							in order to continue receiving payouts.
						</Typography>
					);
				break;
			case EventCategory.SubscriptionRenewalFirstReminder:
			case EventCategory.SubscriptionRenewalSecondReminder:
				eventProps.icon = 'dollar';

				eventProps.message = (
					<Typography>
						Your subscription payment is approaching soon.
					</Typography>
				);

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: { href: '/admin?tab=billing' },
					},
				];
				break;
			case EventCategory.SubscriptionPaymentFailed:
				eventProps.icon = 'dollar';

				eventProps.message = (
					<Typography>
						Snap! Your subscription payment didn't process. Update your card in
						your{ ' ' }
						{ toSimpleLink( {
							link: { href: '/wallet?chargeAccount=true' },
							text: 'wallet',
						} ) }
						.
					</Typography>
				);

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: { href: '/wallet?chargeAccount=true' },
					},
				];
				break;
			case EventCategory.AdminFinishedOnBoardingWithoutPayTo:
				eventProps.icon = 'pay';

				eventProps.message = (
					<Typography>
						Please set up your pay-to account in{ ' ' }
						{ toSimpleLink( { link: { href: '/wallet' }, text: 'My Wallet' } ) }.
					</Typography>
				);

				eventProps.links = [
					{
						type: 'primary',
						text: 'Add',
						link: { href: '/wallet' },
					},
				];
				break;
			case EventCategory.AdminFinishedOnBoardingWithoutPayFrom:
				eventProps.icon = 'pay';

				eventProps.message = (
					<Typography>
						Please set up your charge account in{ ' ' }
						{ toSimpleLink( { link: { href: '/wallet' }, text: 'My Wallet' } ) }.
					</Typography>
				);

				eventProps.links = [
					{
						type: 'primary',
						text: 'Add',
						link: { href: '/wallet' },
					},
				];
				break;
			case EventCategory.ClientFinishedOnBoardingWithoutPaymentMethod:
				eventProps.icon = 'pay';

				if ( isAssignedPlannerOrAdmin( user, event.clientUser ) ) {
					eventProps.message = (
						<Typography>
							{ linkCustomer( event ) } does not have a payment method set up yet.
						</Typography>
					);
				} else {
					eventProps.message = (
						<Typography>
							No payment method set up yet. Click{ ' ' }
							{ toSimpleLink( { link: { href: '/wallet' }, text: 'here' } ) } to add
							one!
						</Typography>
					);
					eventProps.links = [
						{
							type: 'primary',
							text: 'Add',
							link: { href: '/wallet' },
						},
					];
				}
				break;
			case EventCategory.ClientUserEventDateChanged:
				eventProps.icon = 'calendar';

				eventProps.message = (
					<Typography>
						{ linkCustomer( event ) } updated their event date. Head over to their
						profile to review the change.
					</Typography>
				);

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.clientUser.user,
							type: LinkObjectType.Contact,
						} ),
					},
				];
				break;
			case EventCategory.VendorDisputeOpened:
				eventProps.icon = 'warning';
				if ( isAssignedPlannerOrAdmin( user, event.clientUser ) ) {
					eventProps.message = (
						<Typography>
							{ ' ' }
							A Payment dispute has been initiated on{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
									baseUserId: event.contact.customer.id,
								} ),
								text: event.invoice.title,
							} ) }{ ' ' }
							by { linkCustomer( event ) }. The dispute is under review and they
							will be notified when the status is updated.{ ' ' }
						</Typography>
					);
				} else {
					eventProps.message = (
						<Typography>
							Payment dispute on { linkObject( event.invoice ) } has been initiated.
							The dispute is under review and you will be notified when the
							status is updated.
						</Typography>
					);
				}

				break;
			case EventCategory.VendorDisputeWonByVendor:
			case EventCategory.VendorDisputeWonByClient:
				const disputeIsAgainstUsersOrg =
					user.organization?.id === event.vendor.id;
				const userIsViewingForCustomer =
					!disputeIsAgainstUsersOrg &&
					( isAssignedPlannerOrAdmin( user, event.clientUser ) ||
						rpcShared.contacts.isCustomer( event.contact, user.user.id ) );
				const resultForVendorDisputeWonByVendor = userIsViewingForCustomer
					? 'lost'
					: 'won';
				const resultForVendorDisputeWonByClient = userIsViewingForCustomer
					? 'won'
					: 'lost';
				const result =
					event.category == 'VendorDisputeWonByVendor'
						? resultForVendorDisputeWonByVendor
						: resultForVendorDisputeWonByClient;

				eventProps.icon = 'warning';
				eventProps.message = (
					<Typography>
						Dispute on { linkObject( event.invoice ) } has been updated to { result }.
						Please view updated invoice{ ' ' }
						{ toSimpleLink( {
							link: getDetailLink( {
								object: event.invoice,
								type: LinkObjectType.Invoice,
							} ),
							text: 'here',
						} ) }
						.
					</Typography>
				);

				if ( isAssignedPlannerOrAdmin( user, event.clientUser ) ) {
					eventProps.message = (
						<Typography>
							A Payment dispute submitted by { linkCustomer( event ) } for{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
									baseUserId: event.clientUser.user.id,
								} ),
								text: event.invoice.title,
							} ) }{ ' ' }
							has been { result }. The invoice has been updated to reflect this
							outcome.
						</Typography>
					);
				}

				break;
			case EventCategory.MemberAssignedContact:
				if ( isOrgUser( user ) && user.id === event.orgUser.id ) {
					eventProps.icon = 'connected';
					eventProps.message = (
						<Typography>
							Woohoo! You were just assigned { linkCustomer( event ) }. Happy
							Planning!
						</Typography>
					);
					eventProps.links = [
						{
							type: 'secondary',
							text: 'View',
							link: getDetailLink( {
								object: event.contact.customer,
								type: LinkObjectType.Contact,
							} ),
						},
					];
				}
				break;
			case EventCategory.InvoicePaymentsScheduledByClient:
				eventProps.icon = 'disable-autopay';

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.invoice,
							type: LinkObjectType.Invoice,
						} ),
					},
				];

				eventProps.message = (
					<Typography>
						Auto-pay has been turned on for { linkObject( event.invoice ) }. All
						future installments will be paid on the due date with the original
						payment method.
					</Typography>
				);
				break;
			case EventCategory.InvoicePaymentsScheduledByPlanner:
				eventProps.icon = 'disable-autopay';

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.invoice,
							type: LinkObjectType.Invoice,
						} ),
					},
				];

				eventProps.message = (
					<Typography>
						Auto-pay has been turned on for { linkObject( event.invoice ) } by{ ' ' }
						{ getUsername( event.plannerUser ) }. All future installments will be
						paid on the due date with the original payment method.
					</Typography>
				);
				break;
			case EventCategory.InvoicePaymentsDescheduledByClient:
				eventProps.icon = 'disable-autopay';

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.invoice,
							type: LinkObjectType.Invoice,
						} ),
					},
				];

				eventProps.message = (
					<Typography>
						Auto-pay has been turned off for { linkObject( event.invoice ) }.
						Remaining payments on this invoice will need to be paid manually.
					</Typography>
				);
				break;
			case EventCategory.InvoicePaymentsDescheduledByPlanner:
				eventProps.icon = 'disable-autopay';

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.invoice,
							type: LinkObjectType.Invoice,
						} ),
					},
				];

				eventProps.message = (
					<Typography>
						Auto-pay has been turned off for { linkObject( event.invoice ) } by{ ' ' }
						{ getUsername( event.plannerUser ) }. Remaining payments on this invoice
						will need to be paid manually.
					</Typography>
				);
				break;
			case EventCategory.InvoicePaymentsDescheduledByFailure:
				eventProps.icon = 'warning';
				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							Auto-pay has been turned off for { linkObject( event.invoice ) } due
							to failed payment attempts. Please update payment information in{ ' ' }
							{ toSimpleLink( { link: { href: '/wallet' }, text: 'My Wallet' } ) }{ ' ' }
							and ensure payments are made manually.
						</Typography>
					);
				}
				if ( isAssignedPlannerOrAdmin( user, event.clientUser ) ) {
					eventProps.message = (
						<Typography>
							Auto-pay has been turned off for{ ' ' }
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.invoice,
									type: LinkObjectType.Invoice,
									baseUserId: event.clientUser.user.id,
								} ),
								text: event.invoice.title,
							} ) }{ ' ' }
							which was sent to { linkCustomer( event ) } due to failed payment
							attempts. They will need to log in to update their payment method
							on the My Wallet page.
						</Typography>
					);
				}
				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.invoice,
							type: LinkObjectType.Invoice,
						} ),
					},
				];
				break;
			case EventCategory.InstallmentPaymentRescheduled:
				eventProps.icon = 'warning';

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.invoice,
							type: LinkObjectType.Invoice,
						} ),
					},
				];

				eventProps.message = (
					<Typography>
						An auto-payment has failed for { linkObject( event.invoice ) }. We will
						reattempt in 2 days. Please ensure payment information is correct.
					</Typography>
				);
				break;
			case EventCategory.PaymentBlockedByStripeRequirements:
				eventProps.icon = 'warning';

				eventProps.links = [
					{
						type: 'secondary',
						text: 'View',
						link: getDetailLink( {
							object: event.invoice,
							type: LinkObjectType.Invoice,
						} ),
					},
				];

				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							{ linkObject( event.vendor ) } was unable to accept your payment on{ ' ' }
							{ linkObject( event.invoice ) }. No action needed on your part - we
							will straighten this out.
						</Typography>
					);
				} else if (
					isAssignedPlannerOrAdmin( user, event.clientUser ) &&
					user.id !== event.orgUser.id
				) {
					eventProps.message = (
						<Typography>
							{ linkObject( event.vendor ) } was unable to accept a payment attempt
							by { linkCustomer( event ) } for { linkObject( event.invoice ) }. No
							action needed on your part - we will straighten this out.
						</Typography>
					);
					eventProps.links = [
						{
							type: 'secondary',
							text: 'View',
							link: getDetailLink( {
								object: event.invoice,
								type: LinkObjectType.Invoice,
							} ),
						},
					];
				} else if ( isOrgUser( user ) ) {
					eventProps.message = (
						<Typography>
							{ linkCustomer( event, LinkCustomerLinkType.Contact ) } attempted to
							pay invoice: { linkObject( event.invoice ) } but was unable to
							proceed. Our payment processor Stripe needs a bit more information
							from you. Click{ ' ' }
							{ toSimpleLink( { link: { href: '/admin' }, text: 'here' } ) } to
							update your account and receive payments. Once updated, please
							notify your client to resubmit payment.
						</Typography>
					);
					eventProps.links = [
						{
							type: 'secondary',
							text: 'View',
							link: { href: '/admin' },
						},
					];
				}
				break;
			case EventCategory.DocumentFileReplaced:
			case EventCategory.DocumentSharedWithCustomer:
			case EventCategory.DocumentSharedWithOrg:
			case EventCategory.DocumentSharedWithOrgByPlannerObo:
				const documentEvent = documentEvents( event, user );
				eventProps.icon = documentEvent.icon;
				eventProps.message = documentEvent.message;
				eventProps.links = documentEvent.links;
				eventProps.createdAt = event.createdAt;
				break;
			case EventCategory.ClientAssignedPlannerPermissions:
				eventProps.icon = 'star-outline';

				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							Yay, you assigned permissions to { linkObject( event.vendor ) }.
						</Typography>
					);
				}

				if ( isOrgUser( user ) ) {
					eventProps.message = (
						<Typography>
							Yay, { linkCustomer( event ) } assigned you permissions!
						</Typography>
					);
				}
				break;
			case EventCategory.ClientUpdatedPlannerPermissions:
				eventProps.icon = 'star-outline';

				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							Yay, permissions are updated for { linkObject( event.vendor ) }!
						</Typography>
					);
				}

				if ( isOrgUser( user ) ) {
					eventProps.message = (
						<Typography>
							{ linkCustomer( event ) } updated permissions for you!
						</Typography>
					);
				}
				break;
			case EventCategory.VendorRequestedPlannerPermissions:
				eventProps.icon = 'star-outline';

				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					const hereLink = toSimpleLink( {
						link: { href: `/vendors?vendorId=${ event.vendor.id }` },
						text: 'here',
					} );
					eventProps.message = (
						<Typography>
							{ toSimpleLink( {
								link: getDetailLink( {
									object: event.vendor,
									type: LinkObjectType.Organization,
								} ),
								text: event.vendor.name,
							} ) }{ ' ' }
							is requesting permissions from you. Click { hereLink } to get
							started!
						</Typography>
					);
				}
				if ( isOrgUser( user ) ) {
					eventProps.message = (
						<Typography>
							Hurrah! Permissions request sent to { linkCustomer( event ) }.
						</Typography>
					);
				}
				break;
			case EventCategory.ClientRemovedPlannerPermissions:
				eventProps.icon = 'star-outline';

				if ( rpcShared.contacts.isCustomer( event.contact, user.user.id ) ) {
					eventProps.message = (
						<Typography>
							Permissions have been removed for { linkObject( event.vendor ) }. They
							will no longer be able to act on your behalf.
						</Typography>
					);
				}
				if ( isOrgUser( user ) ) {
					if ( user.organization.id === event.vendor.id ) {
						eventProps.message = (
							<Typography>
								{ linkCustomer( event ) } removed permissions from you. You will no
								longer be able to act on their behalf.
							</Typography>
						);
					} else {
						eventProps.message = (
							<Typography>
								{ linkCustomer( event ) } removed permissions from{ ' ' }
								{ linkObject( event.vendor ) }. They will no longer be able to act
								on their behalf.
							</Typography>
						);
					}
				}
				break;
			case EventCategory.ProposalResent:
			case EventCategory.ProposalSent:
			case EventCategory.ProposalSentAwaiting:
				eventProps = getProposalSentEventProps(
					eventProps,
					user,
					props.targetUser
				);
				break;
			case EventCategory.ProposalDueSoon:
				eventProps = getProposalDueSoonEventProps( {
					eventProps,
					user,
					targetuser: props.targetUser,
					refresh: props.refresh,
				} );
				break;
			case EventCategory.ProposalPastDue:
				eventProps = getProposalPastDueEventProps( {
					defaultEventProps: eventProps,
					user: user,
					refresh: props.refresh,
				} );
				break;
			case EventCategory.ProposalAcceptedByPlanner:
			case EventCategory.ProposalAcceptedByCustomer:
				eventProps = getProposalAcceptedEventProps( eventProps, user );
				break;
			case EventCategory.ProposalViewed:
				eventProps = getProposalViewedEventProps( eventProps, user );
				break;
			case EventCategory.QuickPaymentPaid:
				eventProps = quickPaymentPaidEventProps( eventProps, user );
				break;
			case EventCategory.InquiryReceived:
				const hasLeadsAccess = rpcShared.inquiries.canAccessLeads(
					event.inquiry.organization
				);
				const inquiryLinkProps = hasLeadsAccess
					? {
						link: {
							className: 'legacyLink',
							href: `/lead/${ event.inquiry.id }`,
							as: `/lead/${ event.inquiry.id }`,
						},
					  }
					: user?.organization?.id === event.inquiry.organization.id
						? {
							onClick: () => setPremiumSubscriptionRequiredModalOpen( true ),
					  }
						: undefined;
				if ( inquiryLinkProps ) {
					const inquirySender = `${ event.inquiry.firstName } ${ event.inquiry.lastName }`;
					const openModal = () => setPremiumSubscriptionRequiredModalOpen( true );
					eventProps = {
						event,
						icon: 'lead',
						links: [
							{
								type: 'secondary',
								text: 'View',
								...inquiryLinkProps,
							},
						],
						message: (
							<Typography>
								{ user?.organization?.id === event.inquiry.organization.id
									? 'You have'
									: `${ event.inquiry.organization.name } has` }{ ' ' }
								a new lead:{ ' ' }
								{ hasLeadsAccess ? (
									<>
										<Link { ...inquiryLinkProps.link }>{ inquirySender }</Link>.
										Click{ ' ' }
										{ toSimpleLink( {
											link: inquiryLinkProps.link,
											text: 'here',
										} ) }
									</>
								) : (
									<>
										<LinkButton onClick={ openModal }>{ inquirySender }</LinkButton>
										. Click <LinkButton onClick={ openModal }>here</LinkButton>
									</>
								) }{ ' ' }
								to view it.
							</Typography>
						),
					};
				}
				break;
			case EventCategory.UserAddedToNotificationGroup:
				// guest users can't see this, so we don't account or them here
				eventProps = {
					event,
					icon: 'connected',
					message: (
						<Typography sx={ { wordBreak: 'break-word' } }>
							{ event.orgUser?.id === user.id ||
							event.clientUser?.id === user.id ? (
									'You were'
								) : event.orgUser?.organization?.id === event.vendor?.id ? (
									<>
										{ toSimpleLink( {
											link: {
												href: `/client/${ event.orgUser?.user?.id }`,
											},
											text: `${ event.orgUser?.firstName } ${ event.orgUser?.lastName }`,
										} ) }{ ' ' }
									was
									</>
								) : (
									<>
										{ event.orgUser?.user?.email ||
										event.clientUser?.user?.email ||
										event.guestUser?.user?.email }{ ' ' }
									was
									</>
								) }{ ' ' }
							added to{ ' ' }
							{ event.vendor?.id === user?.organization?.id
								? 'your'
								: `${ event.vendor?.name }'s` }{ ' ' }
							{
								rpcShared.emailNotificationGroups.UserFriendlyGroupNames[
									event.emailNotificationGroup.groupTitle
								]
							}{ ' ' }
							notification group.
						</Typography>
					),
				};
				break;
			case EventCategory.UserRemovedFromNotificationGroup:
				// guest users can't see this, so we don't account or them here
				eventProps = {
					event,
					icon: 'decline',
					message: (
						<Typography sx={ { wordBreak: 'break-word' } }>
							{ event.orgUser?.id === user.id ||
							event.clientUser?.id === user.id ? (
									'You were'
								) : event.orgUser?.organization?.id === event.vendor?.id ? (
									<>
										{ toSimpleLink( {
											link: {
												href: `/client/${ event.orgUser?.user?.id }`,
											},
											text: `${ event.orgUser?.firstName } ${ event.orgUser?.lastName }`,
										} ) }{ ' ' }
									was
									</>
								) : (
									<>
										{ event.orgUser?.user?.email ||
										event.clientUser?.user?.email ||
										event.guestUser?.user?.email }{ ' ' }
									was
									</>
								) }{ ' ' }
							removed from{ ' ' }
							{ event.vendor?.id === user?.organization?.id
								? 'your'
								: `${ event.vendor?.name }'s` }{ ' ' }
							{
								rpcShared.emailNotificationGroups.UserFriendlyGroupNames[
									event.emailNotificationGroup.groupTitle
								]
							}{ ' ' }
							notification group.
						</Typography>
					),
				};
				break;
			default:
				break;
		}

		if ( user.isLoggedIn && !eventProps.message ) {
			errorReporting.captureErrorInSentry(
				new Error( `Unhandled ${ event.category } event for user ${ user.id }` ),
				user,
				{ event }
			);
			return false;
		}

		return eventProps;
	};

	const eventRows = props.events
		.map( ( event ) => {
			try {
				return getEventRow( event );
			} catch ( error ) {
				console.error( error );
				errorReporting.captureErrorInSentry( error, user, { event } );
				return false;
			}
		} )
		.filter( notEmpty );

	if ( eventRows.length === 0 && props.emptyTable ) {
		return props.emptyTable;
	}

	return (
		<>
			<List data-cy='event-feed'>
				{ eventRows.map( ( eventProps, index ) => (
					<ErrorBoundary
						extraScope={ { event: eventProps } }
						key={ `${ eventProps.id }-${ index }` }
					>
						<EventRow
							{ ...eventProps }
							hideDismissButtons={ props.hideDismissButtons }
							hideActionButtons={ props.hideActionButtons }
							hideAvatar={ props.hideAvatar }
						/>
						{ index < eventRows.length - 1 ? <Divider /> : null }
					</ErrorBoundary>
				) ) }
			</List>
			<LoadMore
				loading={ props.loading }
				loadMore={ props.loadMore }
				hasMore={ props.moreToLoad }
			/>
			<PremiumSubscriptionRequiredModal
				open={ openPremiumSubscriptionRequiredModal }
				onClose={ () => setPremiumSubscriptionRequiredModalOpen( false ) }
			/>
		</>
	);
};

EventFeed.propTypes = {
	targetUser: PropTypes.object, // target user viewing the profile of
	loadMore: PropTypes.func,
	moreToLoad: PropTypes.bool,
	refresh: PropTypes.func, // used to refresh the event feed after taking action on an event
	events: PropTypes.array,
	hideDismissButtons: PropTypes.bool, // used to determine whether to hide the dismiss buttons
	emptyTable: PropTypes.object, // optional component that, if exists, appears when there's no visible rows
	hideActionButtons: PropTypes.bool, // hide the non-dismiss buttons
	hideAvatar: PropTypes.bool, // hide the icon avatar
	loading: PropTypes.bool, // loading state for fetching events
};

export default EventFeed;
